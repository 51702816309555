@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin stepNumber {
  border-radius: 100%;
  display: inline-block;
  width: 3.4rem;
  height: 3.4rem;
  color: white;
  clip-path: circle(50% at 50% 50%);
  text-align: center;
  margin-right: 1rem;
  line-height: 3.4rem;
}

@mixin coverCenterBackground {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin transform-translate-y($value) {
  -webkit-transform: translate3d(0, $value, 0);
  -moz-transform: translate3d(0, $value, 0);
  -o-transform: translate3d(0, $value, 0);
  -ms-transform: translate3d(0, $value, 0);
  transform: translate3d(0, $value, 0);
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 74.9375em) {
      @content;
    }
  }

  @if $breakpoint == small-desktop {
    @media (max-width: 85.375em) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 120.01em) {
      @content;
    }
  }
}
