@keyframes slide-in {
  0% {
    transform: translateY(-13px);
  }

  40% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes open-modal {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  8% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes main {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes enter-top {
  0% {
    top: -30px;
  }

  100% {
    top: 20px;
  }
}
