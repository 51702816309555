@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$agt-platform-primary: mat.define-palette(mat.$pink-palette);
$agt-platform-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$agt-platform-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$agt-platform-theme: mat.define-light-theme(
  (
    color: (
      primary: $agt-platform-primary,
      accent: $agt-platform-accent,
      warn: $agt-platform-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($agt-platform-theme);

/* You can add global styles to this file, and also import other style files */

html {
  font-size: 100% !important; // 0.625rem = 10px -> 10/16 = 0.625
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  // background-color: $bg-color;
  // @include respond(small-desktop) {
  //   font-size: 59.375%; // 0.625rem = 9.5 -> 9.5/16px
  // }

  // @include respond(tab-land) {
  //   font-size: 56.25%; // 0.625rem = 9 -> 9/16px
  // }

  // @include respond(tab-port) {
  //   font-size: 53.125%; // 0.625rem = 8.5 -> 8.5/16px
  // }

  // @include respond(phone) {
  //   font-size: 56.25%; // 0.625rem = 9 -> 9/16px
  // }
}

body {
  font-family: 'Roboto', sans-serif;
  color: #455263;
  background-color: #f9fafc;
  height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../../../sass/global-style/abstracts/variables.scss';
@import '../../../sass/global-style/abstracts/mixins.scss';
@import '../../../sass/style.scss';
.mat-menu-panel {
  min-height: unset !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
:root {
  --background: #f5f8fb;
  --text: #152c5b;
  --primary-color: #37c1ce;
  --primary-disabled: #b8e6ed;
  --primary-shadow: #37c1ce66;
  --accent-secondary: #dee9ec;
  --accent-tertiary: #dff2f3;
  --light-grey: #a6bac0;
  --dark-grey: #677f93;
  --green-text: #01bf83;
  --green-background: #e8f8f1;
  --blue-text: #37c1ce;
  --blue-background: #37c1ce17;
  --red-text: #fe6b7f;
  --red-background: #fe6b7f17;
  --orange-text: #f87f49;
  --orange-background: #f87f4917;
  --yellow-text: #f8cf49;
  --yellow-background: #f8cf4917;
}
@font-face {
  font-family: 'icomoon';
  src: url('assets/fonts/icomoon.eot?5kiojs');
  src: url('assets/fonts/icomoon.eot?5kiojs#iefix') format('embedded-opentype'), url('assets/fonts/icomoon.ttf?5kiojs') format('truetype'),
    url('assets/fonts/icomoon.woff?5kiojs') format('woff'), url('assets/fonts/icomoon.svg?5kiojs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
