.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 0px;
}
.flex-text-btn {
  display: flex;
  align-items: baseline;
  p {
    margin: 8px;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}
