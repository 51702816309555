.helper-panel {
  margin-top: 1rem;
  margin-bottom: 3.5rem;
  min-height: 3rem;
  background-color: white;
  padding: 1rem;
  color: $light-gray;
  border-radius: 2px;
  border: 1px solid #ebebeb;
  font-size: 0.8125rem;

  p {
    padding-left: 0.875rem;
    margin: 0;
    display: inline;
    vertical-align: middle;
    line-height: 2.6rem;
  }
  .material-icons {
    position: relative;
    top: 3px;
  }
}

.panel-tab {
  font-size: 0.875rem !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  // padding:1.25rem 3rem;
}

.col-md-12,
.col-md-10 {
  padding: 0px;
}

.quote-right-side {
  border-left: 1px dashed $light-gray;
  padding-left:1.25rem;
}

.invoice-right-side {
  border: 1px dashed $light-gray;
  margin-top: 3.1rem;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
}

.pricing {
  display: flex;
  justify-content: space-between;

  div:first-child {
    margin-right: 1rem;
  }
}

.docs-wrapper {
  background-color: white;
  padding: 1rem 3rem 3.5rem 3rem;
  border-radius: 0.7rem;
  margin-top:1.25rem;
  box-shadow: 0px 3px 1rem rgba(0, 0, 0, 0.055);
}

.docs-section {
  display: flex;
  flex-wrap: wrap;
  .doc-card:not(:last-child) {
    margin-right: 12px;
  }
}

.po-right-side {
  padding: 1rem 1rem;
  background-color: $bg-color;
}

.subtotal-price {
  width: 30%;
  border: 1px dashed black;
  padding:1.25rem;
  text-align: center;
  margin: 1.5625rem 0px;
}

.stats-card {
  border-left: 4px solid $primary-color;
  font-size: 0.875rem;
  text-align: center;

  a:hover,
  a {
    color: $dark-gray;
    text-decoration: none !important;
  }
}

.counter-panel {
  min-height: 58.5rem;
  margin-top:1.25rem;
}

.welcome-panel {
  min-height: 52rem;
  position: relative;

  @include respond(phone) {
    min-height: unset;
    height: 100%;
  }

  [class^='col'] {
    padding: 0px !important;
  }

  .panel-body {
    padding: 0px;
    min-height: auto;
    width: 100%;
    height: 43rem;

    @include respond(phone) {
      height: 60rem;
    }
  }

  .row {
    margin: 0;
  }

  .panel-left {
    height: 100%;
    min-width: 33%;
    display: block;
    .step {
      list-style: none;
      display: block;
      border-bottom: 1px solid $light-gray-2;
      font-size: 1rem;
      white-space: normal;
      // float: left;
      width: 100%;
      height: auto;
      word-wrap: break-word;
      font-weight: 700;
      color: $dark-gray-2;
      &-number {
        background-color: $light-gray-2;
        @include stepNumber;

        &-active {
          background-color: $primary-color;
          @include stepNumber;
        }
      }
    }
    a {
      white-space: normal;
      display: block;
      width: 100%;
      height: 100%;
      padding: 2.3rem;
      word-wrap: break-word;
    }
    .active {
      color: $dark-gray;
      border-bottom: 3px solid $primary-color;
      background-color: $light-gray-3;
    }
  }

  .label {
    font-size: 1.45rem;
    color: $dark-gray-2;
    font-weight: 600;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      color: $dark-gray-2;
    }
  }

  .panel-right {
    height: 90%;
  }
}

.panel-right {
  display: block;
  min-width: 67%;
  height: 100%;
  padding: 3rem;
  height: 38.3rem;
  border-left: 1px solid $light-gray-2;
}

.panel-todo {
  margin-top:1.25rem;
  min-height: 46.5rem;

  .panel-header {
    border-bottom: 1px solid $light-gray-2;
    padding:1.25rem;
    font-size: 1rem;
    color: $dark-gray-2;
    font-weight: 600;

    p {
      margin-bottom: 0px !important;
    }
  }

  .panel-body {
    padding: 0px;
  }

  .todo-item {
    list-style: none;
  }
}

.todo-item {
  border-left: 3px solid $primary-color;
  padding: 1rem 0;
  border-bottom: 1px solid $light-gray-2;
  min-height: 8.5rem;
  display: flex;

  .todo-date {
    border-right: 1px solid $light-gray-2;
    margin: 0 auto;
    text-align: center;
    & :first-child {
      font-weight: 600;
      font-size: 1rem;
      margin: 0;
    }

    & :nth-child(2) {
      font-size: 0.875rem;
      margin: 0;
    }
  }

  .todo-details {
    padding-left: 1rem !important;
    & :first-child {
      margin: 0 !important;
    }
    & :nth-child(2) {
      font-size: 0.875rem;
      margin: 0 !important;
    }
  }
}

.user-details {
  display: flex;
  flex-direction: column;

  #profile-contact-info {
    padding-top: 19px;

    .t-light-gray {
      margin-bottom: 0px;
    }

    div div p:nth-child(even) {
      margin-top: 2px;
      margin-bottom: 6px;
    }

    @include respond(tab-land) {
      padding: 0px 32px 16px 0px;
    }

    @include respond(phone) {
    padding-top: 16px;
    }
  }

  #profile-company-info {
    margin-top: 16px;
    border-top: 1px solid #EFF3F5;

    @include respond(tab-land) {
      margin-top: 0px !important;
      border-top: none;
      padding-left: 16px;
    border-left: 1px solid #EFF3F5;
    }

    .main-title {
      @include respond(tab-land) {
        margin-top: 0px !important;
      }
    }

    @include respond(phone) {
      margin-top: 16px;
      border-top: 1px solid #EFF3F5;
      border-left: unset;
      padding-left: 0px;
    }
  }
}

.user-settings {
  .panel-body {
    padding: 16px;
    border-top: 1px solid #EFF3F5;
  }

  .panel-header {
    display: flex;
    padding: 0 16px;
    border-radius: 8px;
    .show-hide {
      align-self: flex-start;
      margin-left: auto;
      width: 100%;
      &-btn {
        height: 56px;
        width: 100%;
        border: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: unset;
        color: #677f93;
      }
    }
    .show-hide-btn:not(.collapsed) {
      i-feather {
        transform: rotate(180deg);
        transition: transform 0.5s ease-in-out;
      }
    }
  }

  .bttn-primary.change-password {
    margin-top:1.25rem;
    margin-left: auto;
  }

  .d-flex {
    @include respond(phone) {
      flex-direction: column;
    }
  }
}

.panel-header {
  padding: 01.25rem;
  color: #152c5b;
  font-weight: 600;
  background-color: #ffffff;
  p {
    margin-bottom: 0px !important;
    font-size: 1rem;
  }
}

.contact-info-section {
  width: 100%;
}

.company-info-section {
  border-left: 1px solid $light-gray-2;
  clear: both;
}

#company-info-form {
  margin-top: 0.75rem;
}

.nav .nav-tabs {
  a:hover {
    font-size: 0.75rem;
  }
}

.add-supplier-box {
  text-align: center;
}

div[data-notify='container'] {
  width: 100%;
  font-size: 0.875rem;
}

.alert {
  animation: fade 0.2s linear;
}

.collapse.in {
  display: block !important;
}

.payable-invoice {
  background: #f2feff;
  border: 1px solid #1074bb;
  padding: 1rem 0.75rem;
  color: #1074bb;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 2.5rem;
  width: 8rem;
  text-align: center;

  img {
    width: 2.5rem;
    height: 2.2rem;
    margin-top: 5px;
  }
}

.panel {
  box-shadow: 0px 2px 6px #0d818b12;
  border-radius: 8px;
}

.strip {
  background-color: white;
  box-shadow: 0px 3px 13px #00000005;
  border-radius: 4px;
  padding: 2.4rem 2.4rem;
  border: 1px solid #eff3f5;
  box-shadow: 0px 3px 8px rgba(98, 117, 160, 0.08);

  @include respond(phone) {
    padding:1.25rem1.25rem;
  }
}

.doc-view li a {
  font-size: 0.875rem;
  font-weight: 400;
  color: #5a6c7b;
}

.doc-view.nav-tabs > li.active > a {
  color: #455263;
}

.a-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 528px;
  width: 100%;
  padding: 24px;
  margin: 0px auto;
  border-radius: 15px;
  background: white;
  box-shadow: 0px 0px 30px #37c1ce10;
  animation: slide-in 0.5s;
  transition: all 0.3s linear;
  @include respond(phone) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &-body {
    width: 100%;
  }
}
