p,
span,
li,
a {
  font-size: 14px;
}

p {
  margin: 4px 0px;
}

.main-title {
  font-size: 16px;
  color: $main-title-color;
  margin-bottom: 5px;
  margin-top: 0.5rem;
  font-weight: 600;

  a {
    display: inline-block;
    vertical-align: middle;
    color: black;
  }

  a:hover {
    text-decoration: none;
    color: black;
  }
}

.heading-1 {
  color: $main-title-color;
  font-size:1.25rem;
  font-weight: 400;
}

.heading-2 {
  color: #051D4B;
  font-size: 18px;
  font-weight: 600;
}

.heading-3 {
  color: $dark-gray;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 5px 0px;
}

.t-small {
  font-size: 0.75rem;
}

.t-blue {
  color: $primary-color !important;
}

.t-light-gray {
  color: $light-gray;
}

.t-bold {
  font-weight: 600;
}

.t-14 {
  font-size: 0.875rem;
}

.label-2 {
  font-size: 0.75rem;
  color: $dark-gray;
  font-weight: 600;
  margin: 5px 0px;
}

.modal-title {
  font-size: 1rem;
  color: $dark-gray;
  margin: 0;
  font-weight: 600;
}

.stats-number {
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0px !important;
}

.panel-title {
  font-size: 1rem;
  color: $dark-gray;
  margin: 0px 0px 0.7rem 0px;
  font-weight: 600;
}

.need-help {
  color: $primary-color !important;
  font-size: 0.875rem;
  padding-top: 4px;
  cursor: pointer;
}

.sm-text-label {
  color: #9ba5ae;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 400;
}

.text-regular {
  font-size: 1rem;
  color: #5a6c7b;
}

.limit-40 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 40ch;
}

.limit-28 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 28ch;
}

.limit-16 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 16ch;
}

.limit-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 10ch;
}
.t-silver {
  color: #677f93 !important;
}
.t-navy {
  color: #152c5b !important;
}
