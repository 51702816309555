html,
body {
  height: 100%;
}
* {
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
