.card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid $light-gray-2;
  padding: 1rem;
  margin-bottom: 1rem;
}

.doc-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid $light-gray-2;
  padding: 0.8rem;
  min-width: 19.5rem;

  @include respond(phone) {
    min-width: 100%;
    margin-right: 0px;
  }

  div:nth-child(2),
  div:nth-child(3) {
    position: relative;
    align-self: center;
    text-align: center;
    align-items: center;
  }

  span.pull-left {
    color: $light-gray;
  }

  span.pull-right {
    color: $primary-color;
  }

  span.pull-right:nth-child(2) {
    font-size: 2.2rem !important;
  }

  span.pull-right:nth-child(3) {
    font-size: 2.3rem !important;
  }

  .icon:hover {
    color: $red-color;
    cursor: pointer;
  }

  .download:hover {
    color: $primary-color;
    cursor: pointer;
  }

  .file-image-wrapper {
    position: relative;

    .bttn-view {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: -4px;
      left: 58%;
      background-color: white;
      color: #37c1ce;
      transition: transform 0.1s ease-in;
    }

    .bttn-view ~ span {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      font-size: 0.75rem;
      bottom: -4px;
      left: 70%;
      color: $light-gray;
      transition: transform 0.1s ease-in;
    }
  }
}

.doc-card:not(:last-child) {
  margin-bottom: 16px;
}

.doc-card-upload {
  border: 1px dashed $primary-color;
  background-color: #f6fdff;
  margin-right: 12px;
  padding: 0.8rem;
  min-width: 19.5rem;
  .btn-doc-uploader {
    display: none;
  }

  @include respond(phone) {
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 16px;
  }

  #add-btn {
    font-size: 8rem;
    cursor: pointer;
    color: $primary-color;
    text-align: center;
    text-align: center;
    display: block;
    margin-top: 3rem;
  }

  p {
    font-weight: bold;
    text-align: center;
    padding-bottom: 1.5625rem;
    font-size: 0.875rem;
  }
}

.doc-card-upload:hover {
  background-color: rgb(235, 248, 252);
}

.doc-card:hover .bttn-view,
.doc-card:hover .bttn-view ~ span {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.doc-card:not(:hover) .bttn-view,
.doc-card:not(:hover) .bttn-view ~ span {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  transition: all 0.1s ease-in;
}

.contact-card {
  display: flex;
  flex-direction: column;
  width: 26rem;
  margin-top: 1.5625rem;
  border-radius: 3px;
  border: 1px solid $light-gray-2;
  padding: 0.8rem;

  .delete {
    cursor: pointer;
    color: $light-gray;
  }

  .delete:hover {
    color: $red-color;
  }

  div:nth-child(2) {
    align-self: center;
    text-align: center;
    height: 70%;
    p:last-child {
      margin-bottom:1.25rem;
      color: $light-gray;
      font-size: 0.875rem;
    }

    figure {
      margin: 0 auto;
    }
  }

  div:nth-child(3) {
    background-color: $light-gray-3;
    margin: -0.75rem;
    padding: 1rem 1rem;
    border-top: 1px solid $light-gray-2;
    color: $light-gray;
    font-size: 1.45rem;
    height: 30%;

    i {
      font-size: 1rem;
    }

    p:last-child {
      margin: 0;
    }
  }
}

.contacts-section {
  display: flex;
}

.contact-card:not(:last-child) {
  margin-right:1.25rem;
}

.notification-card {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.35rem;
  border-left: 3.5px solid $primary-color;
  cursor: pointer;

  div:first-child {
    padding-left: 1rem;
  }

  figure {
    margin: 0;
    margin-right:1.25rem;
  }

  div:nth-child(2) p:first-child {
    padding-top: 0.6rem;
    margin-bottom: 2px !important;
  }

  div:nth-child(2) p:last-child {
    color: #9e9e9e;
    margin-bottom: 0px !important;
  }

  .clear-notification:last-child {
    margin-left: auto;
    align-self: flex-start;
    display: none;
  }
}

.notification-card:hover {
  background-color: rgb(242, 246, 250);
}

.unread-notification {
  background-color: #e9f8fb;
}

.notification-card:hover .clear-notification {
  display: inline-block;
}

.quantity {
  margin-top: auto;
  display: flex;
  height: 4.5rem;
  justify-self: flex-end;
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  align-items: center;
  justify-content: center;
  & p:first-child {
    margin-right:1.25rem;
  }

  input {
    width: 4.5rem;
    height: 58%;
    margin: 0rem 1rem;
    border-radius: 5px;
    padding: 1rem;
    border: 1px solid $light-gray-2;
  }
}

.product-active {
  border: 1px solid rgb(84, 178, 255);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
}

.card-agricultural {
  min-height: 28rem;
  width: 24%;
  margin-right: 1%;

  .quantity {
    margin-top: auto;
    display: flex;
    height: 4.5rem;
    justify-self: flex-end;
    background-color: rgb(241, 241, 241);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    border-top: 1px solid $light-gray-2;
    align-items: center;
    padding-left: 1rem;

    @include respond(small-desktop) {
      padding-left: 1rem;
    }

    & p:first-child {
      margin-right:1.25rem;
    }

    input {
      width: 4.5rem;
      height: 58%;
      margin: 0rem 1rem;
      border-radius: 5px;
      padding: 1rem;
      border: 1px solid $light-gray-2;
    }
  }
}

.card .package-details table {
  padding: 0rem 1rem;
  margin-top: 0px;
  font-size: 1.35rem;
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;

  td:nth-child(odd) {
    color: #9b9b9b;
    font-weight: 600;
    padding-right: 1rem;
    word-wrap: break-word;
    vertical-align: top;
    text-align: left;
    width: 69%;
  }

  td:nth-child(even) {
    color: $light-gray;
    word-break: break-word;
    vertical-align: top;
    text-align: left;
    width: 31%;
  }
}

.supplier-card {
  height: 20.8rem;
  width: 15.83%;

  @include respond(tab-land) {
    width: 24.25%;
  }
}
.supplier-card:not(:nth-child(6n + 6)) {
  margin-right: 1%;
}

@include respond(tab-land) {
  .supplier-card {
    margin-right: 1%;
  }
  .supplier-card:nth-child(4n + 4) {
    margin-right: 0px;
  }
}

.notification-settings-card {
  padding: 0px;
  display: flex;
  flex-direction: column;
  div:first-child {
    font-size: 0.875rem;
    padding: 1.5625rem 2.8rem;
    font-weight: bold;
    height: 50%;
    width: 100%;
    background-color: white;
  }
  div:last-child {
    padding:1.25rem 2.8rem;
    height: 50%;
    width: 100%;
    text-align: center;
    font-size: 0.8125rem;
    background-color: #d4e6e8;
    p {
      margin-bottom: 3px;
    }
  }
}
