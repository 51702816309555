$bg-color: #f9fafc;
$primary-color: #37c1ce;
$dark-blue: #152c5b;
$border-color: #eff3f5;
$border: 1px solid #eff3f5;
$primary-darker-color: #00c9dc;
$secondary-color: #26c5ba;
$dark-gray: #051d4b;
$dark-gray-2: #686868;
$main-title-color: #051d4b;
$light-gray: #677f93;
$light-gray-2: #dde4e7;
$light-gray-3: #fafafa;
$red-color: #fc2424;
