@import '../abstracts/variables';
.form-row {
  display: flex;
}
select {
  background-color: #fff;
}
.form-control {
  box-shadow: none;
  color: #152c5b;
  padding: 0 4px 0 16px;
  font-size: 15px !important;
  border: 1px solid #dee9ec;
  border-radius: 4px;
  height: 40px;
}
label {
  font-size: 15px;
  font-weight: 400;
  color: #152c5b;
  margin-bottom: 4px;
}
input {
  outline: none;
}
.form-group {
  margin-bottom: 16px;
  input,
  textarea {
    font-size: 0.8125rem;
    padding: 1rem;
    border-radius: 0.3rem;
  }
}

.form-group select {
  height: 35px;
}

.select-1 {
  padding: 1rem;
}

.input-group {
  input {
    font-size: 0.8125rem;
    padding: 1rem;
    border-radius: 0.4rem;
  }

  .glyphicon {
    font-size: 0.875rem;
  }
}

button.btn.glyphicon {
  background-color: #ebf3f3;
  border: 1px solid #5a6c7b;
}

.form_radio_label {
  margin-right: 2.7rem;
  margin-bottom: 4px;
  position: relative;
}

input:active,
input:focus,
button:active,
button:focus {
  outline: none;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 3px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0px 20px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid #c4d2d9;
  background-color: transparent;
}

.radio-item input[type='radio']:checked + label:after {
  border-radius: 30px;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 2px;
  content: ' ';
  display: block;
  background: #fff;
  border: 6px solid #00bcd4;
}

.form_radio_btn {
  border: 1px solid rgba(0, 0, 0, 0.18);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.6rem;
  background-color: white;
  transition: background-color 0.2s;

  &::after {
    content: '';
    width: 0%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease;
    height: 0%;
    background: white;
  }
}

.form_radio:checked + .form_radio_label .form_radio_btn::after {
  opacity: 1;
  width: 10px !important;
  height: 10px !important;
}

.form_radio:checked + .form_radio_label .form_radio_btn,
.form_radio + .form_radio_label .form_radio_btn:hover {
  background-color: #37c1ce;
  border: none;
  &::after {
    opacity: 1;
    width: 15px;
    height: 15px;
  }
}

.form_radio {
  display: none;
}

.checkbox_label {
  position: relative;
  margin-right: 1.5625rem;
}

.form_radio:checked + .form_radio_label .form_checkbox_btn {
  background-color: #37c1ce;
  border: none;
  &::after {
    opacity: 1;
  }
}
.form_checkbox_btn {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.18);
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.6rem;
  background-color: white;
  transition: background-color 0.2s;

  &::after {
    content: url(/assets/images/svgs/check.svg);
    position: absolute;
    top: -11px;
    left: 4px;
    padding-top: 0.33px;
    color: white;
    opacity: 0;
    transition: opacity 0.3s;
    width: 13px;
    height: 45px;
  }
}

.checkbox:checked + .checkbox_label .form_checkbox_btn::after {
  opacity: 1;
}

.checkbox:checked + .checkbox_label .form_checkbox_btn {
  background-color: #37c1ce;
  border: none;
}

.checkbox {
  display: none;
}

label {
  font-size: 1rem;
}

.bootstrap-select > .dropdown-toggle {
  height: 3.5rem !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #ced4da;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  border-radius: 4px !important;
  position: relative;
  width: 100%;
  z-index: 1;
  text-align: right;
  white-space: nowrap;
}
#input-group .bootstrap-select > .dropdown-toggle {
  height: 3.7rem !important;
}
.dropdown-menu {
  font-size: 0.8125rem;
}

.bootstrap-select .dropdown-toggle .caret {
  visibility: hidden;
}

.bootstrap-select .dropdown-menu {
  width: 200px !important;
}

.selectpicker:disabled + button.dropdown-toggle {
  background-color: #e9ecef;
  cursor: not-allowed;
}
.help-block {
  margin-top: 4px;
  p {
    font-size: 13px;
  }
}

textarea,
input,
select {
  font-size: 0.8125rem !important;
}

div.searchbar {
  @include respond(phone) {
    width: 100%;
    margin-top: 12px;
  }
}

.search-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e5f1;
  border-radius: 3px;
  height: 2.25rem;
  padding: 0px 29px;
  font: 14px/19px Roboto;
  letter-spacing: 0.17px;
  @include respond(phone) {
    width: 100%;
  }
}
.search-bar::placeholder {
  color: #cacbd5;
}

.search-bar + span::before {
  content: '\f002';
  position: relative;
  top: 0;
  font-size: 1rem;
  transition: 0.5s;
  font-family: FontAwesome;
  color: #cacbd5;
}
.search-bar + span {
  position: absolute;
  padding-left: 12px;
  padding-top: 6px;
}
.search-bar:hover + span::before,
.search-bar:focus + span::before {
  color: #000 !important;
}

div.filter-option-inner-inner {
  color: black !important;
}

.material-icons.md-18 {
  font-size: 18px;
}

//Loan Radio Button
.checkmark {
  background: white;
  min-height: 7rem;
  display: flex;
  padding: 1rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ced3de;
  border-radius: 5px;
  font-size: 1rem;
  color: #5a6c7b;
  font-weight: bold;
  display: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__img {
    width: 5.5rem;
    height: auto;
  }
}

label.container:hover input ~ .checkmark {
  background-color: #f5fbfc;
}

label.container {
  padding: 0px !important;
}

label.container input:checked {
  display: block;
}

/* Hide the browser's default radio button */
label.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

label.container input:checked ~ .checkmark {
  background-color: #f2feff;
  border: 1px solid #37c1ce;
}
select#contact.red-border + button.btn.dropdown-toggle {
  border: #b94a48 1px solid;
}

.phone-number {
  .has-error .ng-select-container {
    border-color: #fc2424 !important;
  }
  .ng-select-disabled .ng-select-container {
    background-color: #e6e6e6;
  }
  .ng-dropdown-panel {
    width: 200px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    max-height: 220px;
  }
  .ng-dropdown-header {
    height: 33px;
    input {
      height: 33px;
    }
  }
  .ng-dropdown-panel-items {
    max-height: 180px !important;
    width: 100%;
  }
  .ng-option {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    transition: ease-in-out 0.15s;
    &:hover {
      background: #f5f5f5;
    }
  }

  .ng-value-container {
    top: 0;
    bottom: 0;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    .ng-value {
      display: flex;
      .flag-arrow {
        width: 8px;
        height: 8px;
        border-bottom: 2px solid #152c5b;
        border-right: 2px solid black;
        transform: rotate(45deg);
        position: relative;
        display: flex;
        margin-left: 12px;
        margin-right: 2px;
        bottom: 1px;
        align-items: center;
        justify-content: center;
      }
      height: 100%;
      align-items: center;
    }
  }
  .ng-select-container {
    height: 40px;
    cursor: pointer !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 3px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 0px !important;
    border: 1px solid #dee9ec;
    padding: 0.5rem 1rem;
  }
  .ng-option-selected {
    background: #37c1ce30;
  }
}
.custom-autocomplete {
  .mat-option {
    .mat-option-text {
      height: 100%;
      width: 100%;
      padding-left: 8px;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }
    &.new-option {
      .mat-option-text {
        background: #37c1ce17;
      }
    }

    line-height: unset;
    padding: 8px;
  }
  .mat-option-ripple {
    display: none !important;
  }
}
.measure-select {
  .start {
    flex: 1;
  }
  ng-select {
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0px;
    color: #152c5b;
  }
  .ng-arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .ng-arrow {
      width: 8px !important;
      height: 8px !important;
      border-bottom: 2px solid #152c5b;
      border-right: 2px solid black;
      transform: rotate(45deg);
      position: relative;
      display: flex;
      margin-left: 12px;
      margin-right: 2px;
      bottom: 1px;
      align-items: center;
      justify-content: center;
      border-radius: 1px;
    }
  }
  &.ng-dropdown-panel {
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    max-height: 220px;
  }
  .ng-dropdown-header {
    height: 33px;
    input {
      height: 33px;
    }
  }
  .ng-dropdown-panel-items {
    border: 1px solid #e7edf2;
    box-sizing: border-box;
    border-radius: 3px;
    max-height: 180px !important;
    width: 100%;
  }
  .ng-option {
    padding: 3px 4px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    transition: ease-in-out 0.15s;
    justify-content: center;
    &:hover {
      background: #f5f5f5;
    }
  }

  .ng-value-container {
    top: 0;
    bottom: 0;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    .ng-value {
      display: flex;
      width: 100%;
      .flag-arrow {
        width: 8px;
        height: 8px;
        border-bottom: 2px solid #152c5b;
        border-right: 2px solid black;
        transform: rotate(45deg);
        position: relative;
        display: flex;
        margin-left: 12px;
        margin-right: 2px;
        bottom: 1px;
        align-items: center;
        justify-content: center;
      }
      height: 100%;
      align-items: center;
    }
  }
  .ng-clear-wrapper {
    display: none !important;
  }
  .ng-select-container {
    height: 40px;
    cursor: pointer !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 4px !important;
    border: 1px solid #e3e5f1;
    padding: 0.5rem 1rem;
    box-shadow: unset;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .ng-option-selected {
    background: #37c1ce30;
  }
}
.error-messages {
  p {
    color: #fc2424 !important;
  }
}
.mat-calendar-body-selected {
  background-color: #37c1ce !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #37c1ce50 !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: transparent !important;
  border: none !important;
}
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #37c1ce50 !important;
}
