.modal-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  background-color: #000a1dc5;
  z-index: 1000;
  font-size: 13px;
  display: none;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 32px;
  box-sizing: border-box;

  @include respond(phone) {
    padding: 0px;
  }

  &-content-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    animation: open-modal 0.4s;
    animation-timing-function: ease-out;
    box-shadow: 0 0 34px 10px rgba(0, 0, 0, 0.2);

    &-lg {
      width: 1136px;
    }

    &-md {
      width: 800px;
    }

    &-sm {
      width: 500px;
    }

    @include respond(phone) {
      width: 100vw;
      min-height: 100vh;
      border-radius: 0px;
    }
  }

  &-heading {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid $light-gray-2;
    font-size: 18px;

    h2 {
      margin-bottom: 0px;
      font: bold 18px Roboto;
      color: $dark-gray;
    }

    @include respond(phone) {
      position: fixed;
      background: white;
      z-index: 1000;
    }
  }

  &-content {
    padding: 16px;
    @include respond(phone) {
      width: 100vw;
      padding: 70px 16px 16px;
    }
  }

  &-actions {
    padding: 16px;
    border-top: 1px solid $light-gray-2;

    button {
      margin: 0px;
    }
  }

  .modal-section-header {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
    color: $dark-gray;
  }

  &-content {
    .upper-part {
      width: 100%;
      height: auto;
      padding-bottom: 1.25rem;
      .tutoral-image-wrapper {
        margin-top: 2.5rem !important;
        width: 80%;
        height: 100%;
        margin: 0 auto;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .bottom-part {
      width: 100%;
      background-color: #00b7cf;
      height: 55%;
      color: white;
      font-size: 1rem;
      padding: 2.18rem 6.25rem 1.5625rem 8.75rem;

      h1 {
        font-weight: 700;
        margin-bottom: 1.25rem;
        font-size: 2.8rem;
      }
    }
  }

  &-left {
    width: 33.33333%;
    height: 87%;
    float: left;
  }

  &-right {
    width: 66.66667%;
    min-height: 45rem;
    background-color: $bg-color;
    float: left;
    position: relative;
    border-left: 1px solid $light-gray-2;
    padding: 3rem;
  }

  .nav.nav-tabs {
    li {
      width: 100%;
      height: 18%;
      list-style: none;
      border-bottom: 1px solid $light-gray-2;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 700;
      color: $light-gray-2;

      .step-number {
        background-color: $light-gray-2;
        @include stepNumber;
      }

      a,
      a:hover,
      a:focus {
        text-decoration: none;
        color: $light-gray-2;
        font-size: 1rem;
        border: none;
      }
    }

    li.completed {
      border-bottom: 1px solid $light-gray-2;

      a {
        color: $dark-gray;
        font-size: 1rem;
      }

      .step-number {
        background-color: $primary-color;
        @include stepNumber;
      }
    }

    li.active {
      border-top: 1px solid $light-gray-2;
      background-color: $bg-color;
      font-size: 1rem;
      position: relative;

      a {
        background-color: $bg-color;
        color: $dark-gray;
      }

      .step-number {
        background-color: $primary-color;
        @include stepNumber;
      }
    }

    li.active::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-color: $primary-color;
      transform-origin: left 2px;
      animation: main 0.3s;
    }
  }
}

.modal-box-heading {
  i {
    font-size: 1.9rem;
    cursor: pointer;
  }
}

.dropdown-menu {
  font-size: 0.875rem;
}

.bootstrap-select .dropdown-toggle .caret {
  visibility: hidden;
}

.bootstrap-select .dropdown-menu {
  width: 200px !important;
}

.product-placeholder-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.25rem;
}

.swal-pdf {
  .swal-title {
    margin: 0px;
    font-size: 1rem;
    margin-bottom: 28px;
    border-bottom: 1px solid $light-gray-2;
    text-align: left;
  }

  .swal-footer {
    display: flex;
    flex-direction: column;
  }

  .swal-button-o {
    background-color: $primary-color;
  }

  .swal-button-o:hover {
    background-color: #00b7cf;
  }
  .swal-button {
    width: 100%;
    padding: 8px 24px;
  }
}

.swal-starting {
  .swal-footer {
    display: flex;
    justify-content: center;
  }
  .swal-button-primary {
    background-color: $primary-color;
    border-radius: 2.5px;
  }

  .swal-button-primary:hover {
    background-color: #00b7cf;
  }
}

div div.swal2-icon.swal2-success.swal2-icon-show {
  font-size: 14.3px !important;
}

ul.tutorial-list {
  list-style-type: none;
  list-style-position: inside;
  padding: 0px;
  margin: 0;
}

ul.tutorial-list li {
  height: 45px;
  padding-left: 44px;
  padding-top: 7px;
}

.nav-circles {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);

  div {
    display: inline-block;
    background-color: #8f8f8f;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    margin-right: 5px;
  }

  .active {
    background-color: white;
  }
}

.push-modal {
  position: fixed;
  display: none;
  width: 25%;
  top: 20px;
  z-index: 1000;
  background: white;
  left: 50%;
  height: 100px;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transform: translateX(-50%);
  animation: enter-top 200ms ease-out;

  p div.progress {
    height: 1rem;
    border-radius: 12px;
    margin-left: auto;
    margin-bottom: 0px;

    div.bg-info {
      background-color: $primary-color !important;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

// .cdk-overlay-pane {
//   animation: open-modal 0.4s;
//   animation-timing-function: ease-out;
// }

.swal2-popup {
  width: 54em !important;
  padding: 2.5em 3em !important;

  h2 {
    font-size: 2.6em;
    margin-bottom: 1.25rem;
  }

  p {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  ul {
    list-style-position: inside !important;
    list-style: decimal;

    li {
      font-size: 1rem !important;
      padding-bottom: 8px;
    }
  }

  .swal2-content {
    font-size: 1rem;
    text-align: left !important;
  }

  .swal2-styled.swal2-confirm {
    background-color: #00b7cf;
    font-size: 1rem;
  }
}

.swal2-icon {
  width: 7rem !important;
  height: 7rem !important;
}

.swal2-x-mark-line-left,
.swal2-x-mark-line-right {
  font-size: 0.875rem !important;
}
  .base-tooltip {
    background: #001031 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 0.97;
    font: normal normal normal 14px/21px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    white-space: pre-wrap !important;
  }