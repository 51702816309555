.img-circular {
  @include coverCenterBackground;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  

  &-lg {
    width: 130px;
    height: 130px;
  }

  &-md {
    width: 64px;
    height: 64px;
  }

  &-sm {
    width: 48px;
    height: 48px;
  }

  &-xs {
    width: 30px;
    height: 30px;
  }
}

.user-img {
  height: 100%;
  transform: translateX(-1rem);
}
