.normal-text {
  font-weight: normal;
  color: #152c5b;
}
.a {
  text-decoration: unset;
  color: #37c1ce;
  font-size: 14px;
  cursor: pointer;
}
.a:hover,
.a:active {
  text-decoration: underline;
  color: #37c1ce;
  cursor: pointer;
}
.gray {
  color: #677f93;
  font-size: 14px;
}
.section-title {
  color: #152c5b;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
  font-family: 'roboto';
  padding: 0px 8px;
}
.hr {
  border: 1px solid #eff3f5;
}







.ttooltip {
  position: relative;
  display: inline-block;
}

.ttooltip .ttooltiptext {
  visibility: hidden;
  width: 161px;
  background-color: #00103a;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  left: -55px;
  bottom: 34px;
  font-size: 12px;
  opacity: 0;
  line-height: 1.3;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    right: 91px;
    bottom: -3px;
    transform: rotate(45deg);
    width: 10px;
    border-right: 5px solid black !important;
    border-bottom: 5px solid black !important;
    border: 0;
    height: 10px;
  }

  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.left-ttool {
  left: -79px !important;

  &::after {
    right: 98px !important;
  }
}

.ttooltip:hover .ttooltiptext {
  visibility: visible;
  animation: fadeIn 0.3s ease-in;
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

