.main-table {
  border-collapse: separate;
  margin-top: 1rem;
  border-spacing: 0 0.875rem;
  color: $dark-gray;

  tr {
    background-color: white;
    font-size: 0.875rem;
    box-shadow: 0 14px 20px -15px rgba(0, 0, 0, 0.055);
  }

  th,
  td {
    border: none !important;
    padding: 1.1rem !important;
    vertical-align: middle !important;
  }

  th {
    font-weight: bold !important;
    font-size: 1rem !important;
  }

  th:first-child {
    display: flex;
    justify-content: center;
  }
  tr td:last-child,
  tr th:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  tr td:first-child,
  tr th:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  @include respond(small-desktop) {
    th,
    td {
      padding: 0.8rem !important;
    }
  }
}

table {
  margin: 1rem 0rem;
}

.table-blue {
  margin-top: 5px;
  margin-bottom: 0px;
  border-spacing: 0 1rem;
  width: 100%;
  border-collapse: separate;
  thead {
    color: white;
    font-size: 0.75rem;
    background-color: $primary-color;
    font-weight: 700;

    th:first-child {
      border-radius: 5px 0 0 5px;
    }

    th:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  tbody tr {
    background-color: white;
    font-size: 0.875rem;
    box-shadow: 0 5px 15px -15px rgba(0, 0, 0, 0.4);

    td:first-child {
      border-radius: 5px 0 0 5px;
    }

    td:last-child {
      border-radius: 0 5px 5px 0;

      @include respond(small-desktop) {
        padding-right: 5px;
      }
    }
  }

  th,
  td,
  thead {
    border: none !important;
    padding: 0.8rem 1.8rem;
    vertical-align: middle !important;
  }
}

.table-wrapper {
  @include respond(tab-land) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.products-table {
  width: 100%;
  margin-bottom: 3rem;

  th {
    color: #33c1ce;
    text-align: left;
    font-weight: unset;
  }

  thead tr {
    border-bottom: 1px solid #2ab7c4;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #d6d6d6;
  }

  th,
  td {
    border-collapse: collapse;
    padding: 1rem 1rem 0.3rem 1rem;
  }
}

.border-box {
  padding:1.25rem 1.5625rem;
  border: 1px solid #e1e1e1;
  word-wrap: break-word;
  max-width: 100%;
  text-shadow: none;

  h2 {
    font-weight: bold;
  }

  table td:nth-of-type(1) {
    padding-right:1.25rem;
  }

  table {
    margin-bottom: 0px !important;
    width: 100%;
    font-size: 0.875rem;
  }
}

.notifications-table {
  width: 97%;
  tr {
    border-bottom: 1px solid #e9e9e9;
  }

  td {
    padding-top: 5px;
    p {
      font-size: 1.35rem;
      margin-bottom: 3px;
      font-weight: 700;
    }
    p:nth-child(2) {
      color: $light-gray;
    }
  }

  .notification-off {
    cursor: pointer;
    color: $light-gray;
  }

  .notification-active {
    cursor: pointer;
    color: #0fd18f;
  }

  td:nth-child(2) {
    text-align: right;
  }
}

.table-borderless {
  font-size: 0.875rem;
  tbody > tr > td,
  thead > tr > th {
    border: none;
  }

  thead > tr > th {
    color: #5a6c7b;
  }

  tbody > tr > td {
    color: #455263;
  }
}

.table-borderless {
  tr:hover td {
    background: #33c1ce10;
  }
}

.ftd-small {
  tbody > tr > td:first-child {
    width: 10%;
  }
}

.mat-sort-header-stem {
  background: none;
  display: none !important;
}

.mat-sort-header-pointer-middle {
  background: none;
  display: none !important;
}

.mat-sort-header-indicator {
  min-height: 7px !important;
}

.total-table-wrapper {
  width: 100%;
}

table.total-table {
  margin: 0px;
  width: 100%;
  font-size: 1.45rem;
  font-weight: 600;
}

table.total-table td:nth-child(odd) {
  width: 58%;
}

table.total-table td {
  padding: 4px;
}

table.total-table tr:last-child {
  border-top: 1px solid #d8dee4;
  font-size: 1.7rem !important;
}

table.total-table td:nth-child(even) {
  width: 42%;
  text-align: right;
}

#last-data-cell {
  padding-bottom:1.25rem;
}

.main-table tr th {
  padding-bottom: 0px !important;
}
.empty-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}
.empty-title {
  color: #455263;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto';
}
.empty-subtitle {
  color: #677f93;
  font-size: 12px;
}

td {
  color: #152c5b;
}

.product-rejected-table {
  width: 100%;
  margin-bottom: 3rem;

  th {
    color: #677f93;
    text-align: left;
    font-weight: unset;
  }

  thead tr {
    border-bottom: 1px solid #eff3f5;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #d6d6d6;
  }

  th,
  td {
    border-collapse: collapse;
    padding: 1rem 1rem 0.3rem 1rem;
  }
  td {
    color: #152c5b;
  }
}
