*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// 16px is the default (browser) font-size
html {
  font-size: 62.5%; // 1rem = 10px -> 10/16 = 0.625
  background-color: $bg-color;
  @include respond(small-desktop) {
    font-size: 59.375%; // 1rem = 9.5 -> 9.5/16px
  }

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9 -> 9/16px
  }

  @include respond(tab-port) {
    font-size: 53.125%; // 1rem = 8.5 -> 8.5/16px
  }

  @include respond(phone) {
    font-size: 56.25%; // 1rem = 9 -> 9/16px
  }
}

body {
  font-family: 'Roboto', sans-serif;
  color: #455263;
  background-color: #F9FAFC;
}

.my-pagination {
  font-size: 0.875rem;
}

.ngx-pagination {
  font-size: 0.75rem;

  @include respond(tab-port) {
    margin-bottom: 9.5rem !important;
  }
}

.tooltip {
  font-size: 0.8125rem;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltip-inner {
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
}

.mat-tooltip.tooltip-return {
  white-space: pre-line;
  left: -70%;
  position: relative;
  width: 10em;
}

.mat-tooltip.tooltip-table {
  white-space: pre-line;
  font-size: 13px;
}

.svgClass {
  height: 58.5rem !important;
}

div[data-notify='container'] {
  width: 88% !important;
}

.main-color {
  color: #5a6c7b !important;
}

.dropdown.bootstrap-select {
  width: 100% !important;
}

.mobile-list-wrapper div,
span {
  font-size: 1.5rem;
}

.mat-sort-header-container {
  justify-content: center;
}
#grn-pay-table {
  .mat-sort-header-container {
    justify-content: center;
    left: 6px;
    position: relative;
  }
  .mat-sort-header-arrow,
  [dir='rtl'] .mat-sort-header-position-before .mat-sort-header-arrow {
    margin: 0 !important;
    position: relative;
    left: 6px;
  }
}
.click {
  cursor: pointer;
}
.ftd-small tbody > tr > td:first-child {
  width: auto !important;
  .form_radio_btn {
    cursor: pointer;
  }
}

.intercom-lightweight-app-launcher.intercom-launcher,
.intercom-launcher-frame.intercom-1esuaw8 {
  @include respond(phone) {
    transform: scale(0.8);
    left: 16px !important;
    bottom: 16px !important;
  }
}

[data-notify='container'] {
  @include respond(phone) {
    right: 12px !important;
  }
}

.responsive-ul-preview {
  @include respond(phone) {
    margin-top: 34px;
  }
}
