.border-top {
  border-top: 1px dashed $light-gray;
  padding: 1rem 0 0 0;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-bottom-2 {
  margin-bottom: 3rem;
}

.margin-top {
  margin-top: 1.25rem;
}

.margin-top-none {
  margin-top: 0;
}

.margin-none {
  margin: 0px !important;
}

.margin-rl {
  margin: 01.25rem;
}

.margin-all {
  margin: 1.25rem1.25rem;
}

.right {
  float: right;
}

.padding-none {
  padding: 0 !important;
}

.clearfix {
  clear: both;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-9 {
  margin-top: 40px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.icon-text {
  display: flex;
  align-items: center;
  font-family: inherit;

  i {
    margin-right: 0.8rem;
    padding-bottom: 0.6rem;
  }
}

.zero-padding-right {
  padding-right: 0px;
}

.zero-padding-left {
  padding-left: 0px;
}

.padding-all {
  padding: 1.25rem;
  padding-top: 0px;
  padding-bottom: 0px;
}

.center-text-btn {
  display: flex;
  align-items: center;

  & :first-child {
    margin-right: 0.8125rem;
    text-align: center;
  }

  & :nth-child(2) {
    margin-bottom: 0px !important;
  }
}

.c-p {
  color: $primary-color !important;
}

.padding-all-2 {
  padding: 1.25rem;
}

.display-inline {
  display: flex;

  & :not(:last-child) {
    margin-right: 1rem;
  }
}

.padding-top-md {
  padding-top: 1.5625rem;
}

.disclaimer {
  display: flex;
  align-items: center;
}

.custom-padding {
  padding: 10px 30px 30px 30px;
}

.margin-vertical-big {
  margin: 3rem 0px !important;
}

.circle-indicator {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  i {
    margin: 0 auto;
  }
}

.circle-indicator.circle-success {
  background-color: #eefff2;
  border: 2px solid #2ca800;
  color: #2ca800 !important;
}

.circle-indicator.circle-fail {
  background-color: #ffeeee;
  border: 2px solid #a80000;
  color: #a80000;
}

.center-items {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5rem;
}

.status {
  border-radius: 12rem;
  text-align: center;
  padding: 1px 2.25rem;
  font-size: 13px;
  font-weight: 400;

  &-blue {
    background-color: #e8f8f9;
    color: #1cb4be;
  }
  &-primary {
    background-color: #effafb;
    border-radius: 12px !important;
    color: #37c1ce;
  }
  &-inactive {
    background-color: #f0f4f8;
    border-radius: 12px !important;
    color: #4974a5;
  }

  &-yellow {
    background-color: rgb(255, 248, 232);
    color: rgb(255, 189, 8);
  }

  &-red {
    color: rgb(255, 71, 71);
    background-color: rgb(255, 220, 220);
  }

  &-orange {
    background-color: #f9f4e8;
    color: #e8a23a;
  }

  &-gray {
    background-color: #e8edf9;
    color: #172b4d;
  }

  &-bg-orange {
    background-color: #fab56a;
    color: #fff2ec;
  }

  &-purple {
    background-color: #f5f4ff;
    color: #836ffc;
  }

  &-green {
    background-color: #eefaf6;
    color: #3caa82;
  }

  &-bg-green {
    background-color: #6ddd91;
    color: #e9fff2;
  }

  &-dark-blue {
    background-color: #eef8ff;
    color: hsl(204, 86%, 53%);
  }
  &-draft {
    background-color: #f5f4ff;
    color: #836ffc;
  }
}

.fl-c {
  display: flex;
  flex-direction: column;
}

.center-c {
  display: flex;
  align-items: center;
}

.mr-0 {
  margin-right: 0px;
}

.flex-pr {
  margin-left: auto !important;
}

.display-ib {
  display: inline-block;
}

.add-bank-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  p {
    font-size: 1rem;
    padding-left: 6px;
    font-weight: bold;
    color: #5a6c7b;
  }
}

.text-right {
  text-align: right;
}

.note {
  background-color: #e8f1f2;
  padding: 0.875rem 1rem;
  width: 50%;
  display: flex;
  align-items: flex-start;
  color: #5a6c7b;

  @include respond(tab-land) {
    width: 100%;
  }

  p {
    font-size: 1.45rem;
  }

  img {
    width: 1.5625rem;
    margin-right: 8px;
  }
}

.flex-strip {
  display: flex;
}

.btn-desktop-txt {
  @include respond(phone) {
    display: none;
  }
}

.btn-mobile-txt {
  display: none;
  @include respond(phone) {
    display: inline-block;
  }
}
.swal2-popup,
.swal2-show {
  border-radius: 10px;
}
h2.swal2-title {
  color: #152c5b;
  font-size: 2.1rem !important;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #37ce73 !important;
}
.swal2-icon.swal2-success * {
  font-size: 0.875rem !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid #37ce7355 !important;
}
.swal-icon--warning__dot {
  margin-left: -3.5px !important;
}
.lined-tooltip {
  background: #001031 0% 0% no-repeat padding-box;
  border-radius: 4px;
  white-space: pre-wrap;
  font: normal normal normal 14px/15px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 21px;
}
