.dropdown-button {
  background-color: transparent;
  font-size: 0.875rem;
  cursor: pointer;
  color: black;
  padding: 0;
}
button {
  cursor: pointer;
}
.dropdown-menu {
  padding: 0px;
}
.dropdown-menu > .dropdown-item {
  font-size: 0.8125rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.back-div {
  cursor: pointer;
  display: inline-block;
}
.btn-primary-transp,
.bttn-transparent-disabled {
  background: transparent;
  border-radius: 10rem;
  position: relative;
  border: 1px solid $primary-color;
  color: $primary-color;
  font-weight: 600;
  padding: 0 2.8rem;
  font-size: 0.875rem;
  margin: 0.5rem;
  height: 2.55rem;

  i {
    font-size:1.25rem;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: -7px;
  }

  span {
    font-size: 1rem;
    vertical-align: middle;
  }
}

.bttn-transparent-disabled {
  color: #ced3de !important;
  border: 1px solid #ced3de !important;
  cursor: default;
}

.bttns-flex {
  margin-top: 1.5625rem;
  display: flex;
}

.two-btns {
  margin: 1rem 0.5rem 0.5rem 0.5rem !important;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  p {
    margin-right: 1rem;
  }

  button {
    height: 2.55rem;
    background-color: transparent;
    border: none;
    font-size: 0.875rem;
    font-weight: bold;
    border: 1px solid $primary-color;
  }

  button:nth-of-type(1) {
    padding: 01.25rem;
    // margin-left: 1rem;
    border-top-left-radius:1.25rem;
    border-bottom-left-radius:1.25rem;
    border-right: none;
  }

  button:nth-of-type(2) {
    padding: 01.25rem;
    border-top-right-radius:1.25rem;
    border-bottom-right-radius:1.25rem;
  }

  button:nth-of-type(1).active {
    background-color: $primary-color;
    color: white;
  }

  button:nth-of-type(2).active {
    background-color: $primary-color;
    color: white;
  }

  .bttn-primary-sm {
    border-radius: 10rem;
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0 1rem;
    border: none;
    border: 1px solid $primary-color;
    background-color: transparent;
    height: 2.55rem;
    width: 5.5rem;
    margin-left: 1rem;
  }
  .bttn-primary-sm.active {
    background: $primary-color;
    color: white;
  }
}

.bttn-primary-sm:hover {
  box-shadow: 0 14px 26px -12px rgba(#04a9be, 0.2), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(#04a9be, 0.2);
}

.bttn-download-sm {
  border-radius: 10rem;
  font-weight: 600;
  font-size: 5px;
  padding: 0.4rem 1rem 0 1rem;
  border: none;
  background-color: #0fd18f;
  height: 2.55rem;
  width: 5.5rem;
  color: white;

  i {
    font-size:1.25rem;
  }
}

.bttn-download-sm:hover {
  box-shadow: 0 14px 26px -12px rgba(#0fd18f, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(#0fd18f, 0.2);
}

.bttn-white {
  background-color: white;
  border-radius: 10rem;
  color: $dark-gray;
  font-weight: 600;
  font-size: 0.875rem;
  min-width: 18rem;
  height: 2.55rem;
  border: none;
  padding: 01.25rem;
  box-shadow: 0 2px 2px 0 hsla(0, 0%, 60%, 0.14), 0 3px 1px -2px hsla(0, 0%, 60%, 0.2), 0 1px 5px 0 hsla(0, 0%, 60%, 0.12);
  margin-right: 0.75rem;
}

.bttn-primary {
  background: $primary-color;
  border-radius: 10rem;
  color: white;
  font: normal normal normal 16px/21px Roboto;
  min-width: 100px;
  margin: 0.5rem;
  transition: box-shadow 0.5s;
  height: 2.5rem;
  padding: 0 20px;
  border: none;
  &.outline {
    background: white;
    border: $primary-color 1px solid;
    color: $primary-color;
  }
  i {
    font-size:1.25rem;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: -7px;
  }

  span {
    font-size: 16px;
    vertical-align: middle;
    position: relative;
    transition: 0.5s;
  }

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(#04a9be, 0.2), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(#04a9be, 0.2);
  }
}

.primary-darker {
  background-color: $primary-darker-color;
}

.bttn-success {
  background: $secondary-color;
  border: none;
  border-radius: 10rem;
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
  min-width: 18rem;
  margin: 0.5rem;
  height: 2.55rem;
  transition: all 0.5s;
  padding: 0 1.8rem;
  box-shadow: 0 2px 2px 0 rgba(0, 212, 124, 0.14), 0 3px 1px -2px rgba(0, 212, 124, 0.2), 0 2px 5px 0 rgba(0, 212, 124, 0.12);

  &:hover {
    box-shadow: 0 14px 26px -12px rgba($secondary-color, 0.2), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($secondary-color, 0.2);
  }
}

.bttn-disabled {
  background-color: #cccccc;
  color: #999999;
  border-radius: 10rem;
  font-weight: 600;
  font-size: 0.875rem;
  min-width: 18rem;
  height: 2.55rem;
  padding: 0 1.8rem;
  border: none;
  margin: 0.5rem;
}

.bttn-add {
  min-width: 124px;
}

.bttn-add span:before {
  content: '\f067';
  position: absolute;
  opacity: 0;
  top: -1px;
  left: -20px;
  font-size: 1rem;
  transition: 0.5s;
  font-family: FontAwesome;
}

.bttn-add:hover span {
  padding-left: 20px;
}

.bttn-add:hover span::before {
  opacity: 1;
  left: 0;
}

.round-button-red {
  border: none;
  border-radius: 50%;
  height: 2.06rem;
  width: 2.06rem;
  position: relative;
  background-color: $red-color;
  margin: 0.5rem;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    display: block;
    font-size:1.25rem;
  }
}

.round-button-red:hover {
  box-shadow: 0 14px 26px -12px rgba($red-color, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($red-color, 0.2);
}

.round-button-primary-exp,
.round-button-primary {
  border: none;
  border-radius: 50%;
  height: 2.06rem;
  width: 2.06rem;
  position: relative;
  background-color: #37c1ce;
  margin: 0.5rem;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    display: block;
    font-size:1.25rem;
  }

  span {
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    visibility: hidden;
  }
}

.round-button-primary.disabled {
  background-color: #d3d3d3;
}

.round-button-primary.disabled:hover {
  box-shadow: 0px 0px 0px;
}

.round-button-primary:hover {
  box-shadow: 0 14px 26px -12px rgba($primary-color, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($primary-color, 0.2);
}

.round-button-primary-exp:hover {
  box-shadow: 0 14px 26px -12px rgba($primary-color, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($primary-color, 0.2);
  width: 18rem;
  border-radius: 19px;
  transition: width 0.4s ease-out;

  i {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-10%, -50%);
    color: white;
    display: block;
    font-size:1.25rem;
  }

  span {
    padding-left: 1rem;
    visibility: visible;
    transition-delay: 0.21s;
  }
}

.round-button-primary-exp:not(:hover) {
  width: 2.06rem;
  border-radius: 20px;
  transition: width 0.4s ease-out;
}

.btn-next {
  background: $primary-color;
  color: white;
  font-weight: 600;
  display: block;
  border-radius: 100px;
  padding: 0.5rem 2.8rem;
  font-size: 1rem;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  border: none;
  &:hover,
  &:active {
    color: white;
  }
}

.btn-round-small {
  border-radius: 50%;
  text-decoration: none;
  display: inline-block;
  color: white;
  width: 2.06rem;
  height: 2.06rem;
  background-color: $primary-color;
  border: none;
  font-size: 1.7rem;
  font-weight: 700;
}

.btn-primary-shadow {
  background: $primary-color;
  border-radius: 10rem;
  color: white;
  font-size: 0.875rem;
  box-shadow: 0px 0.3rem 0.7rem rgba(0, 0, 0, 0.2);
  font-weight: 600;
  margin: 1rem 0.5rem;
  height: 2.5rem;
  padding: 0.7rem1.25rem;
  border: none;
  i {
    font-size:1.25rem;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: -7px;
  }

  span {
    font-size: 1rem;
    vertical-align: middle;
  }
}

.btn-icons,
.btn:focus,
.btn-icons:focus,
.btn-icons:active {
  background: none;
  outline: none !important;
  border: none;

  i {
    color: #979696;
    font-size: 2.2rem;
  }
}

.btn-icons:hover {
  background-color: rgba(#acacac, 0.1);
}

.btn-icons {
  i {
    color: $light-gray;
  }
}

.bttn-icon {
  border-radius: 50%;
  border: none;
  padding: 1rem 1rem 0.5rem 1rem;

  i {
    font-size:1.25rem !important;
  }
}

.bttn-icon.right {
  position: absolute;
  right: 5%;
  top: 50%;
}

.bttn-icon.left {
  position: absolute;
  left: 5%;
  top: 50%;
}

.btn-main {
  cursor: pointer;
}

.btn-circle {
  border: none;
  background-color: #666666;
  width: 2.35rem;
  height: 2.35rem;
  border-radius: 50%;
  color: white;
  i {
    vertical-align: middle;

    font-size:1.25rem;
  }
}

.blue {
  background-color: $primary-color;
}

.large {
  width: 2.06rem;
  height: 2.06rem;
}

.btn.glyphicon {
  top: 0 !important;
}

.ngb-dp-months {
  font-size: 0.8125rem;
}

select.custom-select {
  font-size: 0.75rem !important;
}

.bttn-transparent {
  background: none;
  outline: none;
  border: none;
}

.btn-primary {
  background-color: $primary-color !important;
  border: none;
  font-size: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
  padding: 0.7rem 0.75rem;

  &:hover,
  &:active,
  &:focus {
    background-color: $primary-color !important;
    box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
  }
}

.close-modal {
  cursor: pointer;
  font-size: 1.7rem;
  padding: 1rem;
}

.clear-icon-btn {
  background: transparent;
  border: none;
  span {
    font-size: 1rem;
  }
}

.remove {
  background-color: transparent;
  border: 1px solid $red-color;
  color: $red-color;
  margin-right: 1rem;
}

.edit {
  background-color: transparent;
  border: 1px solid $primary-color;
  color: $primary-color;

  i {
    font-size: 1rem;
  }
}
.bttns-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1.7rem;

  .bttn {
    border-radius: 2px;
    border: none;
    display: flex;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: white;

    i {
      justify-self: flex-start;
      font-size: 2.2rem;
    }
    p {
      margin: 0 auto;
      color: white;
      font-weight: 400;
    }
  }

  .bttn-google {
    background-color: #db4437;
  }

  .bttn-facebook {
    background-color: #0078ff;
  }

  .bttn-email {
    background-color: #5a6c7b;
    height: unset !important;
    min-height: 4.5rem;
  }
}

.btn-outline {
  margin-left: 1rem;
  color: #37c1ce;
  font-size: 0.875rem;
  background: none;
  border: 1px solid #37c1ce;
  padding: 0.6rem;
  font-weight: 100;
}

.btn-outline:hover {
  color: #1f98a3;
  border: 1px solid #1f98a3;
}

.mat-tooltip {
  font-size: 15px !important;
}

button.btn.dpo {
  background-color: #1074bb !important;
  color: white;
}

.dpo {
  display: block !important;
  margin-left: 0px !important;
  width: 100%;
  font-size: 1.45rem;
  font-weight: bold;
  padding: 6px;
}

button.btn-outline.dpo {
  color: #1074bb;
  border: 1px solid #1074bb;
  font-size: 1.45rem;
  background-color: transparent !important;
  margin-top:1.25rem;
}

.bttn-secondary {
  background-color: white;
  border-radius: 10rem;
  border: none;
  display: flex;
  color: $primary-color;
  font-weight: 600;
  align-items: center;
  padding: 0 2.8rem;
  font-size: 0.875rem;
  margin: 0.5rem;
  box-shadow: 0px 3px 3px rgba(209, 215, 216, 0.7);
  height: 2.55rem;
}

.loan-option-button {
  background: white;
  min-height: 7rem;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ced3de;
  border-radius: 5px;
  font-size: 1rem;
  color: #5a6c7b;
  font-weight: bold;
}

.loan-option-button:not(:last-child) {
  margin-right: 1rem;
}

a.bttn-line {
  color: #006fff;
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: 400;
}

.bttn {
  border-radius: 10rem;
  border: none;
  outline: none;
  display: flex;
  font-weight: 400;
  padding: 0 20px;
  font-size: 0.875rem;
  margin: 5px;
  align-items: center;
  height: 2.55rem;
}

.bttn-outline {
  border: 1px solid #dee9ec;
  color: #152c5b;
  background: white;
  min-width: 100px;
  font-size: 16px;
  border-radius: 20px;
  height: 40px;
  transition: all 0.2s ease-in-out;
}

.bttn-outline:hover {
  background: #04a8be12;
  border: 1.5px solid #37c1ce;
}
.top-up {
  font-size: 0.875rem !important;
  border-radius: 24px !important;
  order: 1 !important;
}
.later {
  display: inline-block !important;
  background: transparent !important;
  color: #37c1ce !important;
  text-decoration: underline !important;
  font-size: 0.875rem !important;
}
.actions {
  justify-content: space-between !important;
}

.bttn-sm {
  height: 32px;
  font-size: 14px;
}

.bttn-stretched {
  width: 100%;
}
