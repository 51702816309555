.disabled .round-tab {
  width: 3rem;
  height: 3rem;
  line-height: 2.8rem;
  display: inline-block;
  border-radius: 3rem;
  background: #fff;
  border: 2px solid #dde4e7;
  color: white;
  background-color: #dde4e7;
  text-align: center;
  margin-right: 1rem;
  font-size: 1rem;
}

.nav-tabs [data-toggle='tab']:hover {
  background: transparent;
  border: none;
}

.stepper-wrapper {
  position: relative;
  height: 100%;
  margin-bottom: 3.2rem;

  .stepper {
    position: absolute;
    width: 100%;
    transition: width 0.2s ease-in-out;
  }

  .tab-pane {
    animation: fade 0.6s;
  }

  .nav.nav-tabs {
    list-style: none;
    width: 100%;
    z-index: 888;
    margin-bottom: 2.5rem;

    @include respond(phone) {
      background-color: white;
      top: 52px !important;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      position: fixed;
      box-shadow: 0px 3px 8px rgba(98, 117, 160, 0.1);
    }

    li {
      display: flex !important;
      align-items: center;
      padding-left: 1rem;
      box-shadow: 0px 3px 8px rgba(98, 117, 160, 0.1);
      border-radius: 3px;
      background-color: white;
      position: relative;
      display: inline-block;
      height: 5rem;

      @include respond(phone) {
        box-shadow: none;
        width: unset !important;
        white-space: unset !important;
      }
    }

    li.active {
      @include respond(phone) {
        a {
          display: inline-flex;
          align-items: center;
        }
      }
    }

    li:first-child:nth-last-child(5),
    li:first-child:nth-last-child(5) ~ li {
      width: 18.93%;
    }

    li:first-child:nth-last-child(4),
    li:first-child:nth-last-child(4) ~ li {
      width: 24%;
    }

    li:first-child:nth-last-child(3),
    li:first-child:nth-last-child(3) ~ li {
      width: 32.4%;
    }

    li:first-child:nth-last-child(2),
    li:first-child:nth-last-child(2) ~ li {
      width: 49.5%;
      @include respond(phone) {
        // width: 49.5% !important;
      }
    }

    a {
      font-size: 1rem;
      border: none;
      z-index: 100;
      background-color: transparent !important;
      padding: 0px;
      text-align: left !important;

      @include respond(phone) {
        text-align: center !important;
      }

      .step-text {
        padding-top: 3px;
      }
    }
    li:not(:last-child) {
      margin-right: 1%;
    }

    li.completed,
    li.disabled {
      @include respond(phone) {
        a span.step-text {
          display: none;
        }
      }
    }

    li.active {
      @include respond(phone) {
        a span.step-text {
          display: inline-block;
        }
      }
    }

    li.completed,
    li.active {
      position: relative;
      color: #5a6c7b;
      border-left: 3px solid $primary-color;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;

      @include respond(phone) {
        border-left: 0px;
      }

      .round-tab {
        width: 2.9rem;
        height: 2.9rem;
        line-height: 3rem;
        display: inline-block !important;
        border-radius: 3rem;
        background: $primary-color;
        color: white;
        text-align: center;
        font-size: 1.55rem;
        margin-right: 1rem;
      }

      & > a {
        color: #5a6c7b;
      }
    }

    .active::after {
      content: '';
      position: absolute;
      background-color: white;
      width: 1.5625rem;
      height: 1.5625rem;
      top: 100%;
      left: 16%;
      z-index: 1;
      transform: rotate(45deg);
      margin-top: -0.78rem;
      @include respond(phone) {
        display: none;
      }
    }

    .disabled > a {
      color: $light-gray-2;
    }
  }
}

.round-tab {
  position: relative;
}

.help-block p,
.help-block,
.has-error {
  margin-bottom: 0px !important;
  color: #fc2424 !important;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.step-progress-bar-wrapper {
  width: 100%;
  height: 4px;
  background: #d7e5e6;
  position: absolute;
  left: 0;
  bottom: 0;
}

.loan-step-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 68%;
  margin: 0 auto;
  margin-bottom: 12vh;

  .step-header {
    margin-top: 3.5rem;
    margin-bottom: 1.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step-content {
    width: 100%;
  }

  label {
    color: #5a6c7b;
    font-size: 1rem;
    font-weight: 400;
  }

  .step-title {
    font-size: 2.3rem;
    color: #5a6c7b;
    font-weight: bold;
  }

  .input-group span {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .form-control {
    height: 3.8rem;
    border: 1px solid #e3e5f1 !important;
  }

  .form-row:not(:last-child) {
    margin-bottom: 1rem;
  }

  .row {
    margin: 0 !important;
  }

  .col-md-4 {
    padding-left: 0 !important;
  }
}

//ngx-charts
.total-value {
  display: none !important;
}

.item-value {
  display: none !important;
}

.total-label {
  font-size: 13px !important;
  color: #152c5b;
  margin: 5px !important;
  font-weight: 600;
}
.item-label {
  font-size: 10px !important;
  color: #677f93 !important;
  margin-left: 0px !important;
  justify-content: flex-end;
}
.legend-items {
  display: flex;
  flex-direction: column;
}
.item-percent {
  font-size: 10px !important;
  margin-top: -6px;
}

.legend-item {
  margin: 5px;
  display: flex !important;
}

//the place of charts container
.charts {
  margin-top: -40px;
  margin-left: 50px;
}
.item-color {
  margin-top: -2px;
  width: 5px !important;
  height: 5px !important;
  float: left;
}

.ngx-charts {
  width: 100px;
}
.align-self-center {
  width: 200px;
}
.item-percent {
  margin-left: unset !important ;
  margin-right: 4px;
  font-weight: 600;
}

.item-label {
  order: 3;
}

//the css of the dropDown List
.mat-form-field {
  display: flex !important;
  justify-content: flex-end !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #152c5b !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #37c1ce !important;
}
.mat-option-text {
  color: #152c5b !important;
}

element.style {
  transform-origin: 50% 15px 0px;
  font-size: 10px;
  opacity: 1;
  min-width: calc(100% + 64px);
  transform: scaleY(1);
}
.mat-select-panel:not([class*='mat-elevation-z']) {
  margin-top: 33px;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #152c5b !important;
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #152c5b !important;
}
.mat-form-field.mat-form-field-appearance-legacy {
  border-color: #152c5b !important;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #37c1ce !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0) !important;
}
.mat-form-field-ripple {
  background-color: #37c1ce !important;
}
.no-background {
  background-color: transparent !important;
}
