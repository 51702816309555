/* You can add global styles to this file, and also import other style files */
@import './global-style/abstracts/variables';
@import './global-style/abstracts/mixins';
@import './global-style/components/buttons';
@import './global-style/components/cards';
@import './global-style/components/images';
@import './global-style/components/_modals';
@import './global-style/components/panels';
@import './global-style/components/_tables';
@import './global-style/components/wizards';
@import './global-style/components/texts';
@import './global-style/components/toasts';
@import './global-style/components/forms';
@import './global-style/components/avenews-logo';
@import './global-style/layout/headers';
@import './global-style/layout/panels';
@import './global-style/layout/spacing';
@import './global-style/layout/grid';
@import './global-style/layout/general';
@import './global-style/base/animations';
@import './global-style/base/base';
@import './global-style/base/typography';
@import './global-style/base/utilities';
:root {
  --background: #f5f8fb;
  --text: #152c5b;
  --primary-color: #37c1ce;
  --primary-disabled: #b8e6ed;
  --primary-shadow: #37c1ce66;
  --accent-secondary: #dee9ec;
  --accent-tertiary: #dff2f3;
  --light-grey: #a6bac0;
  --dark-grey: #677f93;
  --green-text: #01bf83;
  --green-background: #e8f8f1;
  --blue-text: #37c1ce;
  --blue-background: #37c1ce17;
  --red-text: #fe6b7f;
  --red-background: #fe6b7f17;
  --orange-text: #f87f49;
  --orange-background: #f87f4917;
  --yellow-text: #f8cf49;
  --yellow-background: #f8cf4917;
}

.mat-snack-bar-container {
  background: #222429 0% 0% no-repeat padding-box !important;
  border-radius: 14px !important;
  margin-bottom: 21px !important;
}
