.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  width: 100%;
  padding: 24px;
  margin: 0px auto;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 30px #37c1ce10;
  animation: slide-in 0.5s;
  transition: all 0.3s linear;
}
