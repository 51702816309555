.succes-toast {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e7f5ea;
  border: 1px solid #a2c4a5;
  padding: 8px;
  border-radius: 4px;
  margin: 0px 8px;
  margin-bottom: 24px;
  p {
    font-size: 14px;
    color: #152c5b;
    margin-left: 3px;
    margin-bottom: unset;
    margin-top: unset;
  }
  i-feather {
    color: #307d34;
    width: 20px;
  }
}
